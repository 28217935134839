<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{ $t('GENERAL.DEVICE') }}</h3>
              <span
                class="label label-lg font-weight-bold label-light-primary label-inline"
                >{{ locations.length }}</span
              >
            </div>
          </div>
          <div class="list-box-item">
            <div class="search-box">
              <input
                @input="filterLocations"
                :value="search"
                class="form-control form-control-solid"
                type="text"
                :placeholder="$t('GENERAL.SEARCH')"
              />
            </div>
          </div>
          <div class="card-body">
            <div id="map" style="height: 550px">
              <l-map
                v-if="showMap"
                :zoom="zoom"
                :center="center"
                :options="mapOptions"
                @update:center="centerUpdate"
                @update:zoom="zoomUpdate"
              >
                <l-tile-layer :url="url" :attribution="attribution" />
                <v-marker-cluster ref="clusterRef">
                  <l-marker
                    v-for="(coordinate, key) in coordinates"
                    :key="key"
                    :lat-lng="coordinate.latLng"
                    @click="showDevices(coordinate.id)"
                  >
                    <l-popup>
                      <div>{{ coordinate.title }}</div>
                    </l-popup>
                  </l-marker>
                </v-marker-cluster>
              </l-map>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLocationDevicesLoaded" class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b mt-8">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-center">
              <span class="card-label font-weight-bolder text-dark"
                >Devices</span
              >
              <span
                class="label label-lg label-inline label-light-primary font-weight-bold"
                >{{ locationDevices.length }}</span
              >
            </h3>
          </div>

          <div class="card-body py-0">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-vertical-center table-head-bg"
                  >
                    <thead>
                      <tr class="text-uppercase">
                        <th style="min-width: 120px" class="pl-6">
                          <span>Name</span>
                          <span>&</span>
                          <span>{{ $t('GENERAL.LESSOR') }}</span>
                        </th>
                        <th style="max-width: 120px">Tags</th>
                        <th style="min-width: 120px">Imei</th>
                        <th class="text-right" style="min-width: 120px">
                          <span>Status</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(device, key) in locationDevices"
                        :key="device.id"
                      >
                        <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                          <router-link
                            :to="'/devices/edit/' + device.id"
                            class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >{{ device.name }}
                          </router-link>
                          <div v-if="device.location">
                            <span class="font-weight-bolder">Lessor: </span>
                            <a
                              class="text-muted font-weight-bold text-hover-primary"
                              href="#"
                              >{{ device.location.title }}</a
                            >
                          </div>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-for="tag in device.tags"
                            :key="tag.id"
                            class="label label-lg label-light-primary label-inline mr-2"
                            >{{ tag.name }}</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span class="text-muted font-weight-500">{{
                            device.logger.imei
                          }}</span>
                        </td>
                        <td
                          class="text-right"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span
                            v-if="device.isActive"
                            class="label label-lg label-light-success label-inline"
                            >Online</span
                          >
                          <span
                            v-else
                            class="label label-lg label-light-danger label-inline"
                            >Offline</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import { Icon } from 'leaflet'
import { mapGetters } from 'vuex'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  name: 'DevicesLocation',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
  },
  data() {
    return {
      locations: [],
      coordinates: [],
      locationDevices: [],
      isLocationDevicesLoaded: false,
      zoom: 7,
      center: latLng(44.709554, 16.1646569),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 5,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
      search: '',
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyOib',
      'currentUserCompanyModule',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Devices', route: '/devices/list' },
      { title: 'Devices Locations' },
    ])

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      ApiService.get('locations?pagination=false').then(({ data }) => {
        this.locations = [...data['hydra:member']]
        this.locations.forEach((location) => {
          if (location.address.lat !== null) {
            this.coordinates.push({
              id: location.id,
              latLng: latLng(
                parseFloat(location.address.lat.toFixed(7)),
                parseFloat(location.address.lng.toFixed(7))
              ),
              title: location.title,
            })
          }
        })
      })
    }

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER' ||
      this.currentUserRole === 'ROLE_JUNIOR'
    ) {
      ApiService.get(
        'locations',
        `?company=${this.currentUserCompany}&pagination=false`
      ).then(({ data }) => {
        this.locations = [...data['hydra:member']]
        this.locations.forEach((location) => {
          if (location.address.lat !== null) {
            this.coordinates.push({
              id: location.id,
              latLng: latLng(
                parseFloat(location.address.lat.toFixed(7)),
                parseFloat(location.address.lng.toFixed(7))
              ),
              title: location.title,
            })
          }
        })
      })
    }
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom
    },
    centerUpdate() {},
    showDevices(id) {
      this.locationDevices = []
      ApiService.get('devices', `?location.id=${id}`).then(({ data }) => {
        this.locationDevices = [...data['hydra:member']]
        this.isLocationDevicesLoaded = true
      })
    },
    filterLocations(e) {
      this.search = e.target.value
      this.coordinates = []
      let coordinates = []
      const filteredLocations = this.locations.filter((location) =>
        location.title.toLowerCase().includes(this.search.toLowerCase())
      )
      filteredLocations.forEach((location) => {
        if (location.address.lat !== null) {
          coordinates.push({
            id: location.id,
            latLng: latLng(
              parseFloat(location.address.lat.toFixed(7)),
              parseFloat(location.address.lng.toFixed(7))
            ),
            title: location.title,
          })
        }
      })
      this.coordinates = coordinates
    },
  },
}
</script>

<style lang="scss">
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

.search-box {
  margin: 10px 30px 0 30px;
  max-width: 300px;
  //border-bottom: solid 1px #cccccc;
  position: relative;

  input {
    border: none;
    width: 100%;
    padding: 0.5rem 1rem;
  }
}

#map {
  position: relative;

  //&::before {
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //  height: 100%;
  //  background-color: #000000c7;
  //  z-index: 90;
  //}
  &::after {
    content: 'Professional lifetime license';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 204px;
    height: 24px;
    z-index: 91;
    color: #fff;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.7);
  }
}
</style>
